import React from 'react';
import {
  Link,
  PlatformTab,
  Section,
  PageNavigation,
  PageNavigationLink,
  DataTable,
  DataTableRow,
  DataTableHeader,
  DataTableBody,
  DataTableValueBlock,
  DataTableCodeBlock,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';

const WebTab = ({ data }) => {
  return (
    <PlatformTab>
      <PageNavigation>
        <PageNavigationLink>Z-Index</PageNavigationLink>
        <PageNavigationLink>Elevation</PageNavigationLink>
      </PageNavigation>

      <Section title="Z-Index">
        <Text>Use these variables for the content area of your interface.</Text>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Name</th>
              <th>Value</th>
              <th>Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            {data.allElevationsYaml.edges[0].node.elevations
              .filter(elevation => elevation.name.startsWith('Content'))
              .map(item => {
                return (
                  <DataTableRow layout="thirds" key={item.sass_var}>
                    <DataTableValueBlock>{item.name}</DataTableValueBlock>
                    <DataTableValueBlock>{item.value}</DataTableValueBlock>
                    <DataTableCodeBlock>{item.sass_var}</DataTableCodeBlock>
                  </DataTableRow>
                );
              })}
          </DataTableBody>
        </DataTable>
      </Section>

      <Section title="Elevation">
        <Text>
          Use these variables for expected interface elements like{' '}
          <Link href="/components/modal" isDesignCodeLink>
            Modals
          </Link>
          ,{' '}
          <Link href="/components/toast" isDesignCodeLink>
            Toasts
          </Link>{' '}
          and notices.
        </Text>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Name</th>
              <th>Value</th>
              <th>Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            {data.allElevationsYaml.edges[0].node.elevations
              .filter(elevation => elevation.sass_var.startsWith('$elevation'))
              .map(item => {
                return (
                  <DataTableRow layout="thirds" key={item.sass_var}>
                    <DataTableValueBlock>{item.name}</DataTableValueBlock>
                    <DataTableValueBlock>{item.value}</DataTableValueBlock>
                    <DataTableCodeBlock>{item.sass_var}</DataTableCodeBlock>
                  </DataTableRow>
                );
              })}
          </DataTableBody>
        </DataTable>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
